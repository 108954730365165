import React, { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import Avatar from "../images/avatar.jpg"
import { Files } from "../../redux/api"
import { Table } from "antd"
import { startCase } from "lodash"
import { navigate } from "gatsby"
import { EDITOR_ABSTRACT_DETAIL, LOGIN } from "../../constants/routes"
import { LOGOUT, VIEW_ABSTRACT } from "../../redux/actionTypes"
import { getBadgeStyling, renameStatus } from "../../utils/styling"

const EditorDashboardContent = ({ state }) => {
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({})
  // const [listReviewer, setListReviewer] = useState([])
  const [listFile, setListFile] = useState([])
  const listColumn = [
    { title: "No", dataIndex: "id" },
    { title: "Title", dataIndex: "title" },
    { title: "Author", dataIndex: "author" },
    { title: "Category", dataIndex: "category", render: c => startCase(c) },
    {
      title: "Status",
      dataIndex: "status",
      render: s => (
        <span className={getBadgeStyling(s)}>{renameStatus(s)}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      render: id => (
        <div>
          <button
            className="button button-3d button-circle gradient-grey-orange mr-0 abs-btn"
            value={id}
            onClick={viewAbstractDetail}
          >
            View
          </button>
          {/* <button
            className="button button-3d button-circle gradient-grey-orange mr-0 abs-btn"
            value={id}
            onClick={viewAbstractModal}
          >
            Assign to Reviewer
          </button> */}
        </div>
      ),
    },
  ]
  // const [showModal, setShowModal] = useState(false)
  // const [loading, setLoading] = useState(false)
  // const [selectedReviewer, setSelectedReviewer] = useState([])
  // const [selectedFile, setSelectedFile] = useState(null)

  const viewAbstractDetail = e => {
    const payload = listFile.filter(f => f.id === parseInt(e.target.value))[0]
    dispatch({ type: VIEW_ABSTRACT, payload })
    navigate(EDITOR_ABSTRACT_DETAIL)
  }

  // const viewAbstractModal = e => {
  //   setSelectedFile(parseInt(e.target.value))
  //   setShowModal(true)
  // }

  // const handleCheckboxReviewer = e => {}

  // const handleCheckboxChange = e => {
  //   const temp = [...selectedReviewer]
  //   const idx = temp.indexOf(e.target.value)
  //   if (idx !== -1) temp.splice(idx, 1)
  //   else temp.push(e.target.value)
  //   setSelectedReviewer(temp)
  // }

  // const handleSubmitReviewer = async () => {
  //   setLoading(true)
  //   await Files.assign({ file_id: selectedFile, reviewers: selectedReviewer })
  //   setSelectedReviewer([])
  //   setSelectedFile(null)
  //   setLoading(false)
  //   setShowModal(false)
  // }

  useEffect(() => {
    if (!state.user.profile) return
    setProfile(state.user.profile || {})
    const fetchData = async () => {
      const rFile = await Files.details()
      setListFile(rFile ? (await rFile.json()).files : [])
      // const rReviewer = await Users.reviewer()
      // setListReviewer(rReviewer ? (await rReviewer.json()).users : [])
    }
    fetchData()
  }, [state.user.profile])

  return (
    <div className="content-wrap">
      <div className="container clearfix">
        <div className="col-md-9 mt-4">
          <img
            src={Avatar}
            className="alignleft img-circle img-thumbnail my-0"
            alt="Avatar"
            style={{ maxWidth: "84px" }}
          />
          <div className="border-0 mb-0">
            <h3 className="profile-name mb-0">{profile.fullname}</h3>
            <h5>{profile.country}</h5>
          </div>
          <div className="clear" />
        </div>
        <div className="divider divider-center" />
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="clearfix" id="abstract">
              <div className="row mb-1">
                <div className="col-md-6">
                  <h3 className="float-left vertical-center mb-5">
                    Abstract Submission{" "}
                  </h3>
                </div>
              </div>
              <Table rowKey="id" dataSource={listFile} columns={listColumn} />
            </div>
          </div>
        </div>
        <div className="divider divider-center">
          <i className="icon-circle" />
        </div>
        {/* <Modal
          visible={showModal}
          title="Assign maximum 3 reviewer for selected submission"
          width={600}
          footer={[
            <Button key="back" onClick={() => setShowModal(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type={selectedReviewer.length > 3 ? null : "primary"}
              onClick={handleSubmitReviewer}
              loading={loading}
              disabled={selectedReviewer.length > 3 ? true : false}
            >
              Submit
            </Button>,
          ]}
          onCancel={() => setShowModal(false)}
          onOk={handleSubmitReviewer}
        >
          <Checkbox.Group
            value={selectedReviewer}
            onChange={handleCheckboxReviewer}
          >
            {listReviewer.map((r, i) => (
              <Checkbox
                key={i}
                value={r.id}
                className="m-1 d-flex"
                onChange={handleCheckboxChange}
              >
                {r.fullname}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Modal> */}
        <div className="col-12">
          <button
            className="button button-circle button-border button-white  float-right mr-0 text-center"
            onClick={() => {
              if (profile) dispatch({ type: LOGOUT })
              navigate(LOGIN)
            }}
          >
            Log Out
          </button>
          {/* Modal Log Out*/}
          <div className="modal1 mfp-hide" id="logout">
            <div
              className="block mx-auto"
              style={{ backgroundColor: "#FFF", maxWidth: "500px" }}
            >
              <div className="center" style={{ padding: "50px" }}>
                <h3>Log Out</h3>
                <p className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Nostrum delectus, tenetur obcaecati porro! Expedita nostrum
                  tempora quia provident perspiciatis inventore, autem eaque,
                  quod explicabo, ipsum, facilis aliquid! Sapiente, possimus
                  quo!
                </p>
              </div>
              <div className="section center m-0" style={{ padding: "30px" }}>
                <button
                  className="button button-3d button-circle gradient-grey-orange mr-0"
                  style={{ padding: "10px 22px" }}
                >
                  Cancel
                </button>
                <button
                  className="button button-circle button-border button-white"
                  onClick={() => {
                    if (profile) dispatch({ type: LOGOUT })
                    navigate(LOGIN)
                  }}
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({ state }))(EditorDashboardContent)
